import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_cartBtn = _resolveComponent("cartBtn")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, {
    role: "navigation",
    "aria-label": "mobile navigation header",
    class: "ion-no-border"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, {
        mode: "md",
        id: "mobile-web-navigation"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_buttons, {
            slot: "start",
            id: "mobile-nav-hamburger",
            "data-cy": "mobile-nav-hamburger"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, { color: "medium" })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_ion_buttons, {
            slot: "end",
            style: {"padding":"10px 10px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cartBtn, {
                size: "large",
                color: "medium"
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_ion_title, { class: "ion-text-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: "/",
                "aria-label": "home"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_img, {
                    alt: "Donatos Logo",
                    class: "menu-logo",
                    src: require('@/assets/logo-dark.png')
                  }, null, 8 /* PROPS */, ["src"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}