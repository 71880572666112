import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_name = _resolveComponent("name")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_zip = _resolveComponent("zip")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args))),
    "data-cy": "form-address"
  }, [
    _createVNode(_component_ion_grid, { class: "ion-padding-horizontal" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_row, null, {
          default: _withCtx(() => [
            (_ctx.showname)
              ? (_openBlock(), _createBlock(_component_ion_col, {
                  key: 0,
                  size: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_name, {
                      modelValue: _ctx.nickname,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nickname) = $event)),
                      label: "Address name",
                      labelColor: "medium",
                      error: _ctx.nicknameError,
                      placeholder: "Office",
                      fill: "outline",
                      mode: "md"
                    }, null, 8 /* PROPS */, ["modelValue", "error"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_ion_col, { size: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  label: "Address",
                  fill: "outline",
                  placeholder: "Address",
                  modelValue: _ctx.address1,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.address1) = $event)),
                  required: "",
                  autocomplete: "address-line1",
                  onEnter: _ctx.onSubmit,
                  "data-cy": "input-address1",
                  tabindex: "1",
                  mode: "md"
                }, null, 8 /* PROPS */, ["modelValue", "onEnter"]),
                _createVNode(_component_ion_note, {
                  color: "danger",
                  "data-cy": "error-address1"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.addressError), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_ion_col, { size: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  label: "Apt/Suite/Bldg",
                  fill: "outline",
                  placeholder: "Apt 123",
                  modelValue: _ctx.address2,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.address2) = $event)),
                  autocomplete: "address-line2",
                  onEnter: _ctx.onSubmit,
                  "data-cy": "input-address2",
                  autocapitalize: "words",
                  tabindex: "2",
                  mode: "md"
                }, null, 8 /* PROPS */, ["modelValue", "onEnter"]),
                _createVNode(_component_ion_note, {
                  color: "danger",
                  "data-cy": "error-address2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.address2Error), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_ion_col, { size: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  label: "City",
                  fill: "outline",
                  modelValue: _ctx.city,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.city) = $event)),
                  required: "",
                  autocomplete: "address-level2",
                  onEnter: _ctx.onSubmit,
                  "data-cy": "input-city",
                  autocapitalize: "words",
                  tabindex: "3",
                  mode: "md"
                }, null, 8 /* PROPS */, ["modelValue", "onEnter"]),
                _createVNode(_component_ion_note, {
                  color: "danger",
                  "data-cy": "error-city"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.cityError), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_ion_col, {
              size: "6",
              class: "ion-padding-end"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  label: "State",
                  fill: "outline",
                  placeholder: "OH",
                  modelValue: _ctx.state,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state) = $event)),
                  required: "",
                  autocomplete: "address-level1",
                  onEnter: _ctx.onSubmit,
                  "data-cy": "input-state",
                  autocapitalize: "characters",
                  tabindex: "4",
                  mode: "md"
                }, null, 8 /* PROPS */, ["modelValue", "onEnter"]),
                _createVNode(_component_ion_note, {
                  color: "danger",
                  "data-cy": "error-state"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.stateError), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_ion_col, {
              size: "6",
              class: "ion-padding-start"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_zip, {
                  required: "",
                  autoComplete: "",
                  tabindex: "5",
                  modelValue: _ctx.zip,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.zip) = $event)),
                  fill: "outline",
                  error: _ctx.zipError,
                  label: "Zip Code"
                }, null, 8 /* PROPS */, ["modelValue", "error"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_ion_col, { size: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  label: "Delivery Instructions",
                  fill: "outline",
                  placeholder: "The gate code is 1234#",
                  modelValue: _ctx.deliveryInstructions,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.deliveryInstructions) = $event)),
                  onEnter: _ctx.onSubmit,
                  "data-cy": "input-instructions",
                  tabindex: "6",
                  mode: "md"
                }, null, 8 /* PROPS */, ["modelValue", "onEnter"]),
                _createVNode(_component_ion_note, {
                  color: "danger",
                  "data-cy": "error-instructions"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.instructionsError), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            (_ctx.showDefaultCheck)
              ? (_openBlock(), _createBlock(_component_ion_col, {
                  key: 1,
                  class: "ion-margin-bottom",
                  size: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_checkbox, {
                      "label-placement": "end",
                      modelValue: _ctx.isFavorite,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isFavorite) = $event)),
                      "data-cy": "default-location",
                      class: "mr-4",
                      tabindex: "7"
                    }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Use this as my default address")
                      ])),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_ion_col, { size: "12" }, {
              default: _withCtx(() => [
                (_ctx.submissionError)
                  ? (_openBlock(), _createBlock(_component_ion_text, {
                      key: 0,
                      style: {"font-size":"1.3em"},
                      color: "danger pl-8 ml-4"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.submissionError), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                _createVNode(_component_ion_button, {
                  color: "primary",
                  expand: "block",
                  disabled: _ctx.submitting,
                  fill: _ctx.devalueAction ? 'outline' : 'solid',
                  "data-cy": "button-submit-address",
                  tabindex: "8",
                  type: "submit"
                }, {
                  default: _withCtx(() => [
                    (_ctx.loading)
                      ? (_openBlock(), _createBlock(_component_ion_spinner, {
                          key: 0,
                          name: "lines-small"
                        }))
                      : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.submitBtnText), 1 /* TEXT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["disabled", "fill"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ], 32 /* NEED_HYDRATION */))
}