import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_accountModal = _resolveComponent("accountModal")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!

  return (!_ctx.hideTabNavigation)
    ? (_openBlock(), _createBlock(_component_ion_tab_bar, {
        key: 0,
        slot: "bottom",
        mode: "ios"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_fab, {
            style: {"margin-bottom":"30px"},
            horizontal: "center",
            translucent: "true"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, {
                onClick: _cache[0] || (_cache[0] = () => _ctx.router.push('/menu'))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "order-button" }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("ORDER")
                    ])),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_ion_tab_button, {
            tab: "home",
            class: _normalizeClass({ active: _ctx.isActiveRoute('/') }),
            onClick: _cache[1] || (_cache[1] = () => _ctx.router.push('/'))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                icon: _ctx.isActiveRoute('/') ? _ctx.home : _ctx.homeOutline,
                class: "nav-color"
              }, null, 8 /* PROPS */, ["icon"]),
              _createVNode(_component_ion_label, { class: "nav-color" }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("HOME")
                ])),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]),
          _createVNode(_component_ion_tab_button, {
            tab: "offers",
            class: _normalizeClass({ active: _ctx.isActiveRoute('/offers') }),
            onClick: _cache[2] || (_cache[2] = () => _ctx.router.push('/offers'))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                icon: _ctx.isActiveRoute('/offers') ? _ctx.pricetags : _ctx.pricetagsOutline,
                class: "nav-color"
              }, null, 8 /* PROPS */, ["icon"]),
              _createVNode(_component_ion_label, { class: "nav-color" }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("DEALS")
                ])),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]),
          _createCommentVNode(" Tab Spacer  "),
          _cache[10] || (_cache[10] = _createElementVNode("svg", {
            height: "50",
            viewBox: "0 0 100 50",
            width: "100",
            xmlns: "http://www.w3.org/2000/svg"
          }, null, -1 /* HOISTED */)),
          _createVNode(_component_ion_tab_button, {
            tab: "rewards",
            class: _normalizeClass({ active: _ctx.isActiveRoute('/rewards') }),
            onClick: _cache[3] || (_cache[3] = () => _ctx.router.push('/rewards'))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                icon: _ctx.isActiveRoute('/rewards') ? _ctx.gift : _ctx.giftOutline,
                class: "nav-color"
              }, null, 8 /* PROPS */, ["icon"]),
              _createVNode(_component_ion_label, { class: "nav-color" }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("REWARDS")
                ])),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]),
          _createVNode(_component_ion_tab_button, {
            tab: "Account",
            class: _normalizeClass({ active: _ctx.isActiveRoute('/login') }),
            onClick: _cache[4] || (_cache[4] = () => (_ctx.isLoggedIn ? (_ctx.isAccountModalOpen = true) : _ctx.router.replace('/login')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                icon: _ctx.isActiveRoute('/login') ? _ctx.person : _ctx.personOutline,
                class: "nav-color"
              }, null, 8 /* PROPS */, ["icon"]),
              _createVNode(_component_ion_label, { class: "nav-color" }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("ACCOUNT")
                ])),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]),
          (_ctx.isAccountModalOpen)
            ? (_openBlock(), _createBlock(_component_accountModal, {
                key: 0,
                justModal: "",
                onClose: _ctx.delayAccountModalClose
              }, null, 8 /* PROPS */, ["onClose"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}