import { AvailableTopping, MenuItem } from '@/models/menuModels';
import axios from 'axios';
import { LineItem } from '@/models/cartModels';
import { lineItemMapper } from '@/services/orderService';
import { getLocalStorage, localStorageEnum, replaceLocalStorage } from '@/services/localStorageService';

export interface Bundle {
    id: number;
    name: string;
    image_url: string;
    coupon_number: string;
    is_value: boolean;
    bundle_groups: any[];
    identifier: string;
}

export interface BundleState {
    activeBundle: Bundle | null;
    activeGroupIndex: number | null;
    activeItem: MenuItem | null;
    bundles: Bundle[];
    bundles_loaded: boolean;
    cart: any[];
    identifier: string;
}

const localBundleState = getLocalStorage(localStorageEnum.bundleState);

if (localBundleState) {
    // remove bundles from state
    localBundleState.bundles = [];
    localBundleState.bundles_loaded = false;
}

const BundleState: BundleState = localBundleState ?? {
    activeBundle: null,
    activeGroupIndex: null,
    activeItem: null,
    bundles: [],
    bundles_loaded: false,
    cart: [],
    identifier: '',
};

export default {
    state: BundleState,
    mutations: {
        SET_ACTIVE_BUNDLE(state: BundleState, bundle: Bundle) {
            state.identifier = bundle.coupon_number + '-' + Date.now();
            state.activeBundle = bundle;
            replaceLocalStorage(localStorageEnum.bundleState, state);
        },
        SET_ACTIVE_GROUP_INDEX(state: BundleState, idx: any) {
            state.activeGroupIndex = idx;
        },
        SET_BUNDLES(state: BundleState, bundles: Bundle[]) {
            state.bundles = bundles;
            state.bundles_loaded = true;
        },
        SET_ACTIVE_ITEM(state: BundleState, item: any) {
            if (item) {
                state.activeItem = new MenuItem(item);
            } else {
                state.activeItem = null;
            }
        },
        ADD_LINE_ITEM_TO_CART(state: BundleState, lineItem: LineItem) {
            if (state.activeGroupIndex === null) {
                return;
            }
            if (!state.cart[state.activeGroupIndex]) {
                state.cart[state.activeGroupIndex] = [];
            }
            state.cart[state.activeGroupIndex].push(lineItem);
            replaceLocalStorage(localStorageEnum.bundleState, state);
        },
        CLEAR_BUNDLE_CART(state: BundleState) {
            state.cart = [];
            replaceLocalStorage(localStorageEnum.bundleState, state);
        },
        SET_BUNDLE_CART(state: BundleState, cart: any) {
            state.cart = cart;
        },
        REMOVE_ITEM_FROM_GROUP(state: BundleState, data: any) {
            state.cart[data.groupIndex].splice(data.itemIndex, 1);
            replaceLocalStorage(localStorageEnum.bundleState, state);
        },
    },
    actions: {
        removeItemFromGroup(context: any, data: any) {
            context.commit('REMOVE_ITEM_FROM_GROUP', data);
        },
        clearActiveItem(context: any) {
            context.commit('SET_ACTIVE_ITEM', null);
        },
        resetBundle(context: any) {
            context.commit('SET_ACTIVE_ITEM', null);
            context.commit('CLEAR_BUNDLE_CART', null);
        },
        addToBundle(context: any, quantity = 1) {
            const lineItem = lineItemMapper(
                context.state.activeItem,
                [],
                quantity,
                //@ts-ignore
                new AvailableTopping({
                    flavors: [],
                    dressings: [],
                    toppings: [],
                    updatingSelectedToppings: [],
                }),
                context.state.activeItem?.recipes ? context.state.activeItem?.recipes[0] : null,
                context.state.activeItem?.recipes[0].sizes ? context.state.activeItem?.recipes[0].sizes[0] : null
            );
            context.commit('ADD_LINE_ITEM_TO_CART', lineItem);
        },
        updateLineItem(context: any, lineItem: LineItem) {
            context.commit('ADD_LINE_ITEM_TO_CART', lineItem);
            context.commit('SET_ACTIVE_ITEM', null);
        },
        setActiveBundle(context: any, coupon_number: string) {
            const rawBundle = context.state.bundles.find((bundle: Bundle) => parseInt(bundle.coupon_number) === parseInt(coupon_number));
            if (!rawBundle) return;
            const bundle = JSON.parse(JSON.stringify(rawBundle));
            if (parseInt(coupon_number) !== parseInt(context.state.activeBundle?.coupon_number)) {
                context.dispatch('resetBundle');
            }
            const loc = context.rootState.selectedLocationModule;
            if (loc.location && loc.menu) {
                const menu_items = loc.menu.category.map((c: any) => c.items).flat();
                bundle.bundle_groups.map((group) => {
                    group.items = group.items
                        .map((groupItem) => {
                            let matching_item = menu_items.find((i) => {
                                return i.itemPid === String(groupItem.legacy_item_id) && i.webcatPid == String(groupItem.legacy_category_id);
                            });
                            if (matching_item) {
                                const recipes = matching_item.recipes
                                    .filter((r) => {
                                        return r.recipePid == String(groupItem.legacy_crust_id);
                                    })
                                    .map((r) => {
                                        return {
                                            ...r,
                                            sizes: r.sizes.filter((s) => s.externalId == String(groupItem.legacy_size_id)),
                                        };
                                    });

                                matching_item = {
                                    ...matching_item,
                                    recipes: recipes,
                                };

                                return matching_item;
                            } else {
                                return null;
                            }
                        })
                        .filter((i) => {
                            if (i) {
                                return i.recipes.filter((r) => r.sizes.length > 0).length > 0;
                            }

                            return false;
                        })
                        .filter((i) => i.recipes.length > 0);

                    // if group.items have the same itemPid and webcatPid merge the recipes
                    group.items = group.items.reduce((acc: any, item: any) => {
                        const existingItem = acc.find((i: any) => i.itemPid === item.itemPid && i.webcatPid === item.webcatPid);
                        if (existingItem) {
                            item.recipes.forEach((r) => {
                                const existingRecipe = existingItem.recipes.find((i: any) => i.recipeId === r.recipeId);
                                if (existingRecipe) {
                                    r.sizes.forEach((size) => {
                                        const existingSize = existingRecipe.sizes.find((i: any) => i.sizeId === size.sizeId);
                                        if (!existingSize) {
                                            existingRecipe.sizes = existingRecipe.sizes.concat(size);
                                        }
                                    });
                                } else {
                                    existingItem.recipes = existingItem.recipes.concat(item.recipes);
                                }
                            });
                            return acc;
                        } else {
                            return acc.concat(item);
                        }
                    }, []);

                    group.items = group.items.map((item: any) => {
                        return new MenuItem({
                            ...item,
                            recipes: item.recipes.sort((a, b) => a.recipePid - b.recipePid),
                        });
                    });

                    return group;
                });

                context.commit('SET_ACTIVE_BUNDLE', bundle);
            }
        },
        setActiveGroupIndex(context: any, idx: number) {
            context.commit('SET_ACTIVE_GROUP_INDEX', idx);
        },
        setActiveItem(context: any, item: any) {
            context.commit('SET_ACTIVE_ITEM', item);
        },
        fetchBundles(context: any) {
            const location = context.rootState.selectedLocationModule.location;
            if (!location) {
                return;
            }
            // @TODO: Update this with ApiHelper call to get bundles from new API
            axios.get(process.env.VUE_APP_COUPONS_API_URL + `/stores/${location.id}/coupons/bundles`).then((res) => {
                context.commit('SET_BUNDLES', res.data.data);
            });
        },
        removeBundleFromCart(context: any, coupon_number: string) {
            const bundleItems = context.rootState.orderModule.cartItems.filter((item: any) => {
                return item.bundleIdentifier && item.bundleIdentifier.startsWith(coupon_number);
            });
            const coupon = context.rootState.orderModule.validCoupons.find((c: any) => c.coupon_number === coupon_number);
            if (coupon) {
                context.dispatch('removeCoupon', coupon, { root: true });
            }
            bundleItems.forEach((item: any) => {
                context.dispatch('deleteCartItem', item, { root: true });
            });
        },
        convertBundleToCartItems(context: any, coupon_number: string) {
            const bundleItems = context.rootState.orderModule.cartItems.filter((item: any) => {
                return item.bundleIdentifier && item.bundleIdentifier.startsWith(coupon_number);
            });

            bundleItems.forEach((item: any) => {
                item.bundleIdentifier = null;
            });
        },
        changeCartBundle(context: any, coupon_number: string) {
            const bundleItems = context.rootState.orderModule.cartItems.filter((item: any) => {
                return item.bundleIdentifier && item.bundleIdentifier.startsWith(coupon_number);
            });

            const coupon = context.rootState.orderModule.validCoupons.find((c: any) => c.coupon_number === coupon_number);

            if (coupon) {
                context.dispatch('removeCoupon', coupon, { root: true });
            }

            const newBundleCart: any[] = [];

            bundleItems.forEach((item: any) => {
                const groupIndex = item.bundleIdentifier.split('--')[1];
                if (!Array.isArray(newBundleCart[groupIndex])) {
                    newBundleCart[groupIndex] = [];
                }
                newBundleCart[groupIndex].push(item);
                context.dispatch('deleteCartItem', item);
            });

            context.commit('SET_BUNDLE_CART', newBundleCart);
        },
    },
    getters: {
        getNonValueBundles(state: BundleState) {
            return state.bundles.filter((b) => !b.is_value);
        },
        getAllBundles(state: BundleState) {
            return state.bundles;
        },
        getBundleIdentifier(state: BundleState) {
            return state.identifier;
        },
        activeBundle(state: BundleState) {
            return state.activeBundle;
        },
        activeGroup(state: BundleState) {
            if (state.activeGroupIndex !== null) {
                return state.activeBundle?.bundle_groups[state.activeGroupIndex];
            }

            return null;
        },
        bundlesLoaded(state: BundleState) {
            return state.bundles_loaded;
        },
        activeItem(state: BundleState) {
            return state.activeItem;
        },
        bundleCart(state: BundleState) {
            return state.cart;
        },
        bundleInProgress(state: BundleState) {
            let count = 0;
            state.cart.forEach((item: any) => {
                count = count + (item && Array.isArray(item) ? item.length : 0);
            });
            return state.activeBundle && count > 0;
        },
    },
};
