import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_cartBtn = _resolveComponent("cartBtn")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, {
    role: "navigation",
    "aria-label": "native app navigation",
    class: "ion-no-border py-0 px-0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, { mode: "ios" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_buttons, { slot: "start" }, {
            default: _withCtx(() => [
              (_ctx.backBtn)
                ? (_openBlock(), _createBlock(_component_ion_back_button, {
                    key: 0,
                    "default-href": _ctx.prevRoute,
                    mode: "ios",
                    text: _ctx.backText
                  }, null, 8 /* PROPS */, ["default-href", "text"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_ion_buttons, {
            slot: "end",
            style: {"padding":"10px 10px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cartBtn, {
                size: "large",
                color: "medium"
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_ion_title, {
            class: "ion-text-center",
            "router-link": _ctx.titleRoute
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_img, {
                alt: "Donatos Logo",
                class: "menu-logo",
                src: require('@/assets/logo-dark.png')
              }, null, 8 /* PROPS */, ["src"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["router-link"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}