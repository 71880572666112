import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_text = _resolveComponent("ion-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_col, { size: "6" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_select, {
          "label-placement": _ctx.isValidBirthdate ? '' : 'floating',
          fill: "outline",
          placeholder: "Birth Month",
          modelValue: _ctx.month,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.month) = $event)),
          interface: "action-sheet",
          disabled: _ctx.isValidBirthdate,
          mode: "md"
        }, {
          default: _withCtx(() => [
            _cache[2] || (_cache[2] = _createElementVNode("div", {
              slot: "label",
              class: "label-color"
            }, "Birth Month", -1 /* HOISTED */)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.months, (month, index) => {
              return (_openBlock(), _createBlock(_component_ion_select_option, {
                key: index,
                value: index
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.capitalize(month)), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label-placement", "modelValue", "disabled"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_ion_col, { size: "6" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_select, {
          "label-placement": _ctx.isValidBirthdate ? '' : 'floating',
          fill: "outline",
          placeholder: "Birth Day",
          modelValue: _ctx.day,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.day) = $event)),
          interface: "action-sheet",
          disabled: !_ctx.numberOfDays || _ctx.isValidBirthdate,
          mode: "md"
        }, {
          default: _withCtx(() => [
            _cache[3] || (_cache[3] = _createElementVNode("div", {
              slot: "label",
              class: "label-color"
            }, "Birth Day", -1 /* HOISTED */)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberOfDays, (day) => {
              return (_openBlock(), _createBlock(_component_ion_select_option, {
                key: day,
                value: day
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(day), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label-placement", "modelValue", "disabled"])
      ]),
      _: 1 /* STABLE */
    }),
    (_ctx.isValidBirthdate)
      ? (_openBlock(), _createBlock(_component_ion_text, {
          key: 0,
          class: "ion-text-center full-width mb-8 py-8"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode(" If you need to make a change to your birthday, please "),
            _createElementVNode("a", { href: "/customer-service" }, "contact us", -1 /* HOISTED */),
            _createTextVNode(". ")
          ])),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}