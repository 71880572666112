import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  slot: "label",
  class: "label-color"
}
const _hoisted_2 = { slot: "end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_note = _resolveComponent("ion-note")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_input, _mergeProps(_ctx.$attrs, {
      "label-placement": _ctx.stacked ? 'stacked' : 'floating',
      fill: _ctx.fill,
      modelValue: _ctx.localModelValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localModelValue) = $event)),
      inputmode: "numeric",
      type: "number",
      autocomplete: _ctx.autoComplete ? 'postal-code' : false,
      "data-cy": "input-zip",
      onKeup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('enter'))),
      tabindex: _ctx.tabindex,
      name: "zip",
      class: "custom",
      mode: "md"
    }), {
      default: _withCtx(() => [
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createTextVNode(_toDisplayString(_ctx.label) + " ", 1 /* TEXT */),
              (_ctx.required)
                ? (_openBlock(), _createBlock(_component_ion_text, {
                    key: 0,
                    color: "danger"
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("*")
                    ])),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.searchBtn)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                slot: "end",
                fill: "clear",
                "item-right": "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('enter'))),
                color: "medium"
              }, {
                default: _withCtx(() => [
                  (_ctx.searching)
                    ? (_openBlock(), _createBlock(_component_ion_spinner, {
                        key: 0,
                        name: "lines-small"
                      }))
                    : (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 1,
                        icon: _ctx.searchSharp,
                        color: "medium"
                      }, null, 8 /* PROPS */, ["icon"]))
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }, 16 /* FULL_PROPS */, ["label-placement", "fill", "modelValue", "autocomplete", "tabindex"]),
    _createVNode(_component_ion_note, {
      color: "danger",
      "data-cy": "error-zip"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.error), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    })
  ], 64 /* STABLE_FRAGMENT */))
}