import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ion-text-capitalize bold color-dark inline-list-p mt-4"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.items && _ctx.items.length > 0)
    ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("span", {
            key: item[_ctx.keyName],
            class: "inline-list-item small",
            innerHTML: _ctx.displayFunc ? _ctx.displayFunc(item) : item[_ctx.keyName]?.trim()
          }, null, 8 /* PROPS */, _hoisted_2))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    : _createCommentVNode("v-if", true)
}