<template>
    <ion-row v-if="!showEditView" class="pr-8 ion-margin-top">
        <ion-col size="9" class="pl-16">
            <p class="ion-text-capitalize">{{ account?.name }}</p>
            <p>{{ account.emailAddress }}</p>
            <p v-if="account.phoneNumber">{{ account.phoneNumber?.toString() }}</p>
        </ion-col>
        <ion-col size="3" class="pl-16 pr-8 ion-text-center">
            <ion-button size="small" color="primary" fill="outline" @click="showEditView = true">Edit</ion-button>
        </ion-col>
        <ion-col size="12" class="pl-16">
            <p v-if="account.hasValidBirthdate">
                Birthday: {{ account.birthMonth }} {{ account.birthDay }}<sup>{{ numberSuffix(account.birthDay) }}</sup>
            </p>
            <p>Favorite Donatos: {{ favoriteLocationName }}</p>
            <p v-if="isLoyaltyMember">Member ID: {{ account.rewardsMemberId }}</p>
        </ion-col>
        <ion-col size="12" class="pl-16">
            <p v-if="hasDietaryPreferences">Dietary Preferences: {{ dietaryPreferencesList.join(', ') }}</p>
            <p v-else>Dietary Preferences: None</p>
        </ion-col>

        <ion-col size="12" class="pl-16 interstate-bold">
            <a :href="passwordResetUrl" target="_blank">
                <ion-text fill="clear" color="primary">Reset Password</ion-text>
            </a>
        </ion-col>

        <ion-col v-if="account.pendingEmail" size-xs="12" class="pl-16 interstate-bold">
            <ion-text color="secondary">Pending Email: {{ account.pendingEmail }}</ion-text>
            <text-input :class="verificationCodeClassList" fill="outline" style="margin-top: 10px; width: 200px" label="Verification Code" labelColor="medium" v-model="verificationCode" />
        </ion-col>
    </ion-row>

    <ion-row class="ion-margin-top" v-else>
        <ion-col size="6">
            <name v-model="formValues.firstName" required label="First name" labelColor="medium" fill="outline" />
            <div class="input-error">{{ errors.firstName }}</div>
        </ion-col>
        <ion-col size="6">
            <name v-model="formValues.lastName" required label="Last name" labelColor="medium" fill="outline" />
            <div class="input-error">{{ errors.lastName }}</div>
        </ion-col>
        <birthday :birthdateString="account.birthdate" @updateBirthdate="updateBirthdate($event)" />
        <ion-col size="12">
            <phone v-model="formValues.phoneNumber" label="Phone Number" labelColor="medium" fill="outline" />
            <div class="input-error">{{ errors.phoneNumber }}</div>
        </ion-col>
        <ion-col size="12">
            <email v-model="formValues.emailAddress" required label="Email" labelColor="medium" fill="outline" />
            <div class="input-error">{{ errors.emailAddress }}</div>
        </ion-col>
        <ion-col size="12">
            <text-input v-model="formValues.zipCode" required label="Zip Code" labelColor="medium" fill="outline" autocomplete="postal-code" type="text" />
            <div class="input-error">{{ errors.zipCode }}</div>
        </ion-col>
        <ion-col size="12">
            <selectLocation
                :label="formValues.zipCode?.length === 5 ? 'Favorite Donatos' : 'Favorite Donatos - Enter Zip Code'"
                :locations="locationList"
                :disabled="formValues.zipCode?.length !== 5"
                dataCy="rewards-location-select"
                fill="outline"
                v-model="formValues.favoriteStore"
            />
        </ion-col>
        <ion-col>
            <ion-text>Dietary (check all that apply)</ion-text>
            <ion-item>
                <ion-checkbox @update:modelValue="glutenFree = $event" :modelValue="glutenFree" color="success" label-placement="end" class="sel-option" justify="start">Gluten Free</ion-checkbox>
            </ion-item>
            <ion-item>
                <ion-checkbox @update:modelValue="vegetarian = $event" :modelValue="vegetarian" color="success" label-placement="end" class="sel-option" justify="start">Vegetarian</ion-checkbox>
            </ion-item>
        </ion-col>
        <ion-col size="12">
            <ion-button id="save-button" @click="onSubmit">Save Profile</ion-button>
        </ion-col>
    </ion-row>
</template>

<script lang="ts">
import { IonButton, IonRow, IonCol, IonCheckbox, IonText, IonItem, toastController } from '@ionic/vue';
import { defineComponent, PropType, ref, watch, computed, nextTick, onMounted } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';

import { Account } from '@/models/account/authModels';
import { passwordResetUrl } from '@/services/authConfig';
import Name from '@/components/inputs/name.vue';
import Email from '@/components/inputs/email.vue';
import TextInput from '@/components/inputs/textInput.vue';
import selectLocation from '@/components/inputs/selectLocation.vue';
import { fetchClosestLocationsAddress, fetchLocationDetail } from '@/services/locationService';
import { LOCATION_SEARCH_LIMIT } from '@/constants';
import { StoreLocation } from '@/models/locationModels';
import { updateAccountInfo, verifyEmail } from '@/services/accountService';
import UserProfile from '@/models/account/userProfile';
import { useApiResponse } from '@/composables/useApiResponse';
import Phone from '../inputs/phone.vue';
import { isAreaCodeValid, numberSuffix } from '@/services/stringService';
import Birthday from '@/components/inputs/birthday.vue';
import { useStore } from 'vuex';
import useShowRewards from '@/composables/useShowRewards';

export default defineComponent({
    props: { account: { type: Object as PropType<Account>, required: true } },
    components: {
        IonButton,
        IonRow,
        IonCol,
        Name,
        Email,
        TextInput,
        selectLocation,
        IonText,
        IonCheckbox,
        IonItem,
        Phone,
        Birthday,
    },
    setup(props) {
        const store = useStore();
        const { isLoyaltyMember } = useShowRewards();
        //VALIDATION AND DATA
        const showEditView = ref(false);
        const schema = computed(() => {
            return yup.object({
                customerId: yup.string(),
                firstName: yup.string().required().label('First Name'),
                lastName: yup.string().required().label('Last Name'),
                emailAddress: yup.string().email().required().label('Email'),
                phoneNumber: yup
                    .string()
                    .label('Phone number')
                    .test('areaCode', 'Phone number area code is not valid', (value) => isAreaCodeValid(value)),
                zipCode: yup.string().typeError('Must be a valid zip code').min(5, 'Must be exactly 5 digits').max(5, 'Must be exactly 5 digits').required().label('Zip Code'),
                favoriteStore: yup.number(),
                glutenFree: yup.number(),
                vegetarian: yup.number(),
                birthdate: yup.string(),
            });
        });
        const form = useForm({
            validationSchema: schema,
            initialValues: props.account,
        });

        const vegetarian = ref(+props.account.vegetarian);
        const glutenFree = ref(+props.account.glutenFree);
        const hasDietaryPreferences = computed(() => vegetarian.value || glutenFree.value);
        const dietaryPreferencesList = computed(() => {
            const arr: string[] = [];
            if (vegetarian.value) arr.push('Vegetarian');
            if (glutenFree.value) arr.push('Gluten Free');
            return arr;
        });

        watch(vegetarian, () => (formValues.value.vegetarian = vegetarian.value ? 1 : 0));
        watch(glutenFree, () => (formValues.value.glutenFree = glutenFree.value ? 1 : 0));

        const updateBirthdate = (date: string) => {
            formValues.value.birthdate = date; // yyyy-mm-dd
        };

        const formValues = ref(form.values);
        watch(
            formValues,
            (newVal) => {
                //@ts-ignore
                vegetarian.value = +newVal?.vegetarian ?? false;
                //@ts-ignore
                glutenFree.value = +newVal?.glutenFree ?? false;
                form.values = newVal;
            },
            { immediate: true, deep: true }
        );
        const useFields = () => {
            for (let propName in schema.value.fields) {
                useField(propName);
            }
        };

        const locationList = ref(new Array<StoreLocation>());
        const favoriteLocationName = ref('No Location Selected');

        onMounted(() => {
            useFields();
            setFavoriteLocationName();
        });

        const setFavoriteLocationName = () => {
            // if we already have all the info
            if (typeof formValues.value.favoriteStore == 'string' && locationList.value.length > 0) {
                favoriteLocationName.value = locationList.value.find((location) => +location.id == +(formValues.value.favoriteStore ?? 0))?.name ?? favoriteLocationName.value;
                // if we only have the store number
            } else if (props.account.favoriteStore) {
                fetchLocationDetail(props.account.favoriteStore).then((location) => (favoriteLocationName.value = location.name));
            }
        };

        const isZipReady = computed(() => formValues.value.zipCode?.length == 5);
        watch(
            isZipReady,
            () => {
                if (form.errors && isZipReady.value) {
                    fetchClosestLocationsAddress(form.values.zipCode as string)
                        .then((response) => {
                            locationList.value = response.slice(0, LOCATION_SEARCH_LIMIT);
                        })
                        .catch();
                }
            },
            { immediate: true, deep: true }
        );

        // UPDATE WITH ACCOUNT
        const updateFields = (account) => {
            // @ts-ignore
            form.values = new UserProfile(account);
        };
        nextTick(() => {
            updateFields(props.account);
        });
        watch(
            () => props.account,
            (a) => updateFields(a)
        );

        // TOAST
        const toastMessage = ref('');
        const toast = ref();

        onMounted(async () => {
            toast.value = await toastController.create({
                message: toastMessage.value,
                duration: 2000,
                position: 'middle',
            });
        });

        //REQUEST HANDLING
        const { responseMessage, responseModalVisible } = useApiResponse();
        const onSubmit = form.handleSubmit(() => {
            setFavoriteLocationName();
            updateAccountInfo(new UserProfile(formValues.value))
                .then(() => {
                    toastMessage.value = 'Profile updated successfully!';
                    // eslint-disable-next-line vue/no-mutating-props
                    props.account.birthdate = formValues.value.birthdate ?? '';
                    store.dispatch('refreshAccountInfo');
                })
                .catch((e) => (toastMessage.value = e))
                .finally(() => {
                    toastController
                        .create({
                            message: toastMessage.value,
                            duration: 2000,
                            position: 'top',
                            color: 'success',
                        })
                        .then((toast) => {
                            toast.present();
                            showEditView.value = false;
                        });
                });
        });

        // EMAIL VERIFICATION
        const verificationCode = ref('');
        const isVerificationLoading = ref(false);
        const verificationCodeClassList = ref('');

        const submitEmailVerification = (code) => {
            isVerificationLoading.value = true;
            verifyEmail(code)
                .then((response) => {
                    if (response.toLowerCase() === 'success') {
                        store.dispatch('refreshAccountInfo');
                    } else {
                        verificationCodeClassList.value = 'form-group--error';
                    }
                })
                .catch(() => {
                    verificationCodeClassList.value = 'form-group--error';
                })
                .finally(() => {
                    verificationCode.value = '';
                    setTimeout(() => (verificationCodeClassList.value = ''), 500);
                    isVerificationLoading.value = false;
                });
        };

        watch(verificationCode, (code) => {
            if (code.length == 6) {
                submitEmailVerification(code);
            }
        });

        return {
            passwordResetUrl,
            verificationCode,
            verificationCodeClassList,
            submitEmailVerification,
            isVerificationLoading,
            showEditView,
            meta: form.meta,
            formValues,
            errors: form.errors,
            onSubmit,
            locationList,
            responseMessage,
            responseModalVisible,
            toastMessage,
            vegetarian,
            glutenFree,
            favoriteLocationName,
            hasDietaryPreferences,
            dietaryPreferencesList,
            updateBirthdate,
            numberSuffix,
            isLoyaltyMember,
        };
    },
});
</script>

<style scoped>
p {
    margin: 0;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

ion-col {
    margin-bottom: 10px;
}

#save-button {
    width: 99%;
}

ion-checkbox {
    margin: 0px;
}

ion-button {
    font-size: larger;
    font-weight: 600;
    letter-spacing: 0.03em;
}

@keyframes shakeError {
    0% {
        transform: translateX(0);
    }
    15% {
        transform: translateX(0.375rem);
    }
    30% {
        transform: translateX(-0.375rem);
    }
    45% {
        transform: translateX(0.375rem);
    }
    60% {
        transform: translateX(-0.375rem);
    }
    75% {
        transform: translateX(0.375rem);
    }
    90% {
        transform: translateX(-0.375rem);
    }
    100% {
        transform: translateX(0);
    }
}

.form-group--error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    --border-color: var(--ion-color-danger);
}

.sel-option {
    padding-left: 1rem;
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
}
</style>
