import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/payment-logos/visa.png'
import _imports_1 from '@/assets/payment-logos/mastercard.png'
import _imports_2 from '@/assets/payment-logos/discover.png'
import _imports_3 from '@/assets/payment-logos/amex.png'


const _hoisted_1 = {
  key: 0,
  alt: "Visa Card Logo",
  src: _imports_0
}
const _hoisted_2 = {
  key: 1,
  alt: "MasterCard Logo",
  src: _imports_1
}
const _hoisted_3 = {
  key: 2,
  alt: "Discover Logo",
  src: _imports_2
}
const _hoisted_4 = {
  key: 3,
  alt: "Amex Logo",
  src: _imports_3
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.type.toUpperCase() == $setup.cardTypeEnum.visa)
    ? (_openBlock(), _createElementBlock("img", _hoisted_1))
    : ($props.type.toUpperCase() == $setup.cardTypeEnum.masterCard)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : ($props.type.toUpperCase() == $setup.cardTypeEnum.discover)
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : ($props.type.toUpperCase() == $setup.cardTypeEnum.amex)
          ? (_openBlock(), _createElementBlock("img", _hoisted_4))
          : _createCommentVNode("v-if", true)
}