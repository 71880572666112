import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  slot: "label",
  class: "label-color"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_input = _resolveComponent("ion-input")!

  return (_openBlock(), _createBlock(_component_ion_input, {
    "label-placement": _ctx.stacked ? 'stacked' : 'floating',
    fill: "solid",
    placeholder: "example@gmail.com",
    modelValue: _ctx.localModelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
    "onKeyup:enter": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('enter'))),
    autocomplete: "email",
    type: "email",
    inputmode: "email",
    pattern: "email",
    name: _ctx.name,
    color: "medium",
    class: "custom",
    mode: "md"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.label ? _ctx.label : 'Email Address') + " ", 1 /* TEXT */),
        (_ctx.required)
          ? (_openBlock(), _createBlock(_component_ion_text, {
              key: 0,
              color: "danger"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("*")
              ])),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["label-placement", "modelValue", "name"]))
}