import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  slot: "label",
  class: "label-color"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!

  return (_openBlock(), _createBlock(_component_ion_select, {
    "label-placement": "floating",
    fill: "outline",
    modelValue: _ctx.localModelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
    interface: "action-sheet",
    disabled: _ctx.disabled,
    mode: "md"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.label) + " ", 1 /* TEXT */),
        (_ctx.required)
          ? (_openBlock(), _createBlock(_component_ion_text, {
              key: 0,
              color: "danger"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("*")
              ])),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locations, (location) => {
        return (_openBlock(), _createBlock(_component_ion_select_option, {
          key: location.name,
          value: location.id
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(location.name), 1 /* TEXT */)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "disabled"]))
}