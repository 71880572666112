import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  itemprop: "streetAddress",
  "data-cy": "address1",
  class: "ion-text-capitalize"
}
const _hoisted_2 = {
  key: 0,
  "data-cy": "address2"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  itemprop: "addressLocality",
  "data-cy": "city",
  class: "ion-text-capitalize"
}
const _hoisted_5 = {
  itemprop: "addressRegion",
  "data-cy": "state",
  class: "ion-text-uppercase"
}
const _hoisted_6 = {
  key: 2,
  itemprop: "postalCode",
  "data-cy": "zipcode"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_dynamicSkeleText = _resolveComponent("dynamicSkeleText")!

  return (_ctx.address)
    ? (_openBlock(), _createBlock(_component_ion_card_subtitle, {
        key: 0,
        itemprop: "address",
        class: _normalizeClass(["address-display", { inline: _ctx.inline, dense: _ctx.dense, large: _ctx.large }]),
        itemtype: "http://schema.org/PostalAddress"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.address.address1) + _toDisplayString(_ctx.address.address2 || !_ctx.inline ? '' : ',') + " ", 1 /* TEXT */),
          (_ctx.address.address2)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.address.address2) + _toDisplayString(_ctx.address.address2 && _ctx.inline ? ',' : '') + " ", 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          (!_ctx.inline)
            ? (_openBlock(), _createElementBlock("br", _hoisted_3))
            : _createCommentVNode("v-if", true),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.address.city), 1 /* TEXT */),
          _cache[0] || (_cache[0] = _createElementVNode("span", null, ", ", -1 /* HOISTED */)),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.address.state), 1 /* TEXT */),
          (_ctx.showZip)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, " " + _toDisplayString(_ctx.address.zip ?? _ctx.address.zipcode), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class"]))
    : (_openBlock(), _createBlock(_component_ion_card_subtitle, {
        key: 1,
        class: _normalizeClass({ inline: _ctx.inline })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_dynamicSkeleText, {
            element: "span",
            childElements: _ctx.skeleText,
            animate: ""
          }, null, 8 /* PROPS */, ["childElements"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class"]))
}