import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "body-container" }
const _hoisted_2 = { class: "modal-body" }
const _hoisted_3 = { class: "pb-8" }
const _hoisted_4 = { class: "response-modal-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_modalWrapper = _resolveComponent("modalWrapper")!

  return (_openBlock(), _createBlock(_component_modalWrapper, {
    mode: "ios",
    title: $props.title,
    open: $props.modalVisible,
    onClose: $props.close,
    onDismiss: $props.close,
    maxHeight: "350px",
    height: "350px",
    maxWidth: "450px",
    minHeight: "350px"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", _hoisted_3, _toDisplayString($props.header), 1 /* TEXT */),
          _createElementVNode("p", null, _toDisplayString($props.message), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ion_button, { onClick: $setup.handleAccept }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.confirmationMessage), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]),
          ($props.declineMessage)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                onClick: $setup.handleDecline
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.declineMessage), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["onClick"]))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "open", "onClose", "onDismiss"]))
}